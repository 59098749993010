// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Api from "../../../api/Api.res.js";
import * as React from "react";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DownloadResourcesCss from "./DownloadResourcesCss.res.js";

function reducer(_state, action) {
  if (typeof action !== "object") {
    return "FailedFetch";
  } else {
    return {
            TAG: "Ready",
            _0: action._0
          };
  }
}

function DownloadResources(props) {
  var match = React.useReducer(reducer, "FetchingCategories");
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          $$Promise.wait(Api.fetchResourceCategories(), (function (x) {
                  if (x.TAG === "Ok") {
                    return dispatch({
                                TAG: "SucceedCategoriesFetch",
                                _0: x._0
                              });
                  }
                  SentryLogger.error1({
                        rootModule: "DownloadResources",
                        subModulePath: /* [] */0,
                        value: "make",
                        fullPath: "DownloadResources.make"
                      }, "ResourceCategories::FetchCategories::Error", [
                        "Error",
                        x._0
                      ]);
                  dispatch("FailedCategoriesFetch");
                }));
        }), []);
  var tmp;
  tmp = typeof state !== "object" ? null : Belt_Array.map(state._0, (function (category) {
            var slug = category.slug;
            if (slug === "all-resources") {
              return null;
            } else {
              return JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs(A.make, {
                                      href: "/resources/" + category.slug,
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: category.navigationName,
                                              className: DownloadResourcesCss.resourceName
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: String(category.itemsCount),
                                              className: DownloadResourcesCss.resourceQty
                                            })
                                      ]
                                    }),
                                className: DownloadResourcesCss.listItemWrapper
                              }),
                          className: DownloadResourcesCss.li
                        }, category.slug);
            }
          }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Download Resources",
                      className: DownloadResourcesCss.header
                    }),
                JsxRuntime.jsxs("ul", {
                      children: [
                        tmp,
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: "/resources",
                                    children: JsxRuntime.jsx("span", {
                                          children: "All Resources",
                                          className: DownloadResourcesCss.resourceName
                                        })
                                  }),
                              className: DownloadResourcesCss.allResourcesListItem
                            }, "all-resources-download")
                      ],
                      className: DownloadResourcesCss.ul
                    })
              ],
              className: DownloadResourcesCss.section
            });
}

var Css;

var make = DownloadResources;

var $$default = DownloadResources;

export {
  Css ,
  reducer ,
  make ,
  $$default as default,
}
/* A Not a pure module */
