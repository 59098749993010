// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var section = Cx.cx([
      "flex",
      "flex-col",
      "bg-gray-100",
      "rounded-lg",
      "my-5",
      "py-6"
    ]);

var ul = Cx.cx([
      "pl-0",
      "list-none",
      "mb-0"
    ]);

var listItemWrapper = Cx.cx([
      "border-b",
      "border-dotted",
      "border-gray-200",
      "h-full",
      "flex",
      "flex-col",
      "justify-center"
    ]);

var li = Cx.cx([
      "h-9",
      "px-7.5",
      "hover:cursor-pointer",
      "hover:bg-purple-100",
      "[&_a]:flex",
      "[&_a]:items-center",
      "[&_a]:justify-between",
      "[&_a]:flex-row"
    ]);

var allResourcesListItem = Cx.cx([
      li,
      "hover:bg-transparent",
      "[&_span]:text-teal",
      "[&_span]:mt-5"
    ]);

var allResourcesListItemLinkSpan = Cx.cx([
      "text-teal-600",
      "mt-5"
    ]);

var header = Cx.cx([
      "text-black",
      "text-lg",
      "font-semibold",
      "leading-6",
      "mx-7.5",
      "pb-4",
      "border-b",
      "border-dotted",
      "border-gray-200"
    ]);

var resourceName = Cx.cx([
      "text-gray-600",
      "text-sm",
      "font-medium",
      "leading-5"
    ]);

var resourceQty = "text-black";

var allLink = "mt-5";

export {
  section ,
  ul ,
  listItemWrapper ,
  li ,
  allResourcesListItem ,
  allResourcesListItemLinkSpan ,
  header ,
  resourceName ,
  resourceQty ,
  allLink ,
}
/* section Not a pure module */
